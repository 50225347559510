/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Heading, jsx } from 'theme-ui'
import ContentFaqQuestion from '~/components/Content/Faq/Question'

const ContentFaqTopic = ({ topic }) => {
  return (
    <Box>
      <Heading
        as="h2"
        sx={{ marginBottom: '2rem', variant: ['text.h3', null, 'text.h2'] }}
      >
        {topic.name}
      </Heading>
      <Box sx={{ marginBottom: '4.5rem' }}>
        {topic.questions.map(question => {
          return <ContentFaqQuestion key={question.id} question={question} />
        })}
      </Box>
    </Box>
  )
}

export default ContentFaqTopic

ContentFaqTopic.propTypes = {
  faq: PropTypes.shape({
    name: PropTypes.string,
    questions: PropTypes.array
  })
}
