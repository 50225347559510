/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Container, Heading, jsx } from 'theme-ui'
import ContentFaqTopic from '~/components/Content/Faq/Topic'

const ContentFaq = ({ faq, page }) => {
  return (
    <Box
      color="primary"
      sx={{
        backgroundColor: 'background',
        padding: ['2.5rem 0', null, '6rem 0']
      }}
    >
      <Container sx={{ textAlign: 'center' }}>
        {page.sections &&
          page.sections.map(section => {
            return (
              <Box sx={{ marginBottom: '4rem' }}>
                <Heading as="h1" variant="h1">
                  {section.title}
                </Heading>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: section.description.childMarkdownRemark.html
                  }}
                  sx={{
                    margin: '0 auto',
                    maxWidth: '650px',
                    '& a': {
                      color: 'accent',
                      textDecoration: 'underline',
                      ':hover': { color: 'primary' }
                    },
                    variant: 'text.subhead'
                  }}
                />
              </Box>
            )
          })}
      </Container>
      <Container sx={{ maxWidth: '1200px' }}>
        {faq.topics.map(topic => {
          return <ContentFaqTopic key={topic.id} topic={topic} />
        })}
      </Container>
    </Box>
  )
}

export default ContentFaq

ContentFaq.propTypes = {
  faq: PropTypes.shape({
    topics: PropTypes.array
  }),
  page: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        media: PropTypes.arrayOf(PropTypes.shape({})),
        colorTheme: PropTypes.shape({
          slug: PropTypes.string
        })
      })
    )
  })
}
